import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import people_pc from "../../assets/img/people_pc.png";
import retorno_cliente from "../../assets/img/retorno_cliente.png";
import reducao_custo from "../../assets/img/reducao_custo.png";
import menos_espera from "../../assets/img/menos_espera.png";

// Progress Circle
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { texts } from "../../texts";
import { Link } from "react-scroll";


const percentage = 60;


const openWhatsapp = () => {
  const link = "https://api.whatsapp.com/send?phone=556391000057&text=Ol%C3%A1,%20quero%20informa%C3%A7%C3%B5es%20sobre%20o%20sistema%20UniChat!";
  window.open(link, "_blank");
};

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
       {/*
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{texts.services.title}</h1>
            <p className="font20">
              {texts.services.text}
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title={texts.services.items.efficient.title}
                subtitle={texts.services.items.efficient.text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title={texts.services.items.personalized.title}
                subtitle={texts.services.items.personalized.text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title={texts.services.items.management.title}
                subtitle={texts.services.items.management.text}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title={texts.services.items.integration.title}
                subtitle={texts.services.items.integration.text}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
       */}
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 style={{ marginBottom: '10px' }} className="font40 extraBold">{texts.services.subHeader.title}</h2>
                <h4 style={{ marginBottom: '15px' }} className="font17 semiBold">{texts.services.subHeader.text1}</h4>
                <p className="font15">
                  {texts.services.subHeader.text2}
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <Link style={{ width: "190px" }} to="pricing" spy={true} smooth={true} offset={-10}>
                    <FullButton title={texts.services.buttons.trial} />
                  </Link>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title={texts.services.buttons.more} action={() => openWhatsapp()} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={people_pc} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={retorno_cliente} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={reducao_custo} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={menos_espera} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 15px 15px 5px 15px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px 15px 15px 15px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 65%;
  margin-left: 20%;
  img {
    width: 100%;
    height: auto;
    border-radius: 15px 5px 15px 15px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 40%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px 15px 15px 15px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;