const formatNumber = (inputValue, mask) => {
    const formattedValue = [];
    let inputIndex = 0;
  
    for (let maskIndex = 0; maskIndex < mask.length; maskIndex++) {
      if (inputIndex >= inputValue.length) {
        break; // Parar quando não houver mais dígitos de entrada
      }
  
      if (mask[maskIndex] === '9' && /\d/.test(inputValue[inputIndex])) {
        formattedValue.push(inputValue[inputIndex]);
        inputIndex++;
      } else {
        formattedValue.push(mask[maskIndex]);
      }
    }
  
    return formattedValue.join('');
  };
  
  const formatCPF = (inputValue) => formatNumber(inputValue, '999.999.999-99');
  const formatCNPJ = (inputValue) => formatNumber(inputValue, '99.999.999/9999-99');
  
  const FormatDocument = (value) => {
    const inputValue = value.replace(/\D/g, '');
  
    let formattedInput = {};
    if (inputValue.length <= 11) {
      formattedInput = { type: 'CPF', value: formatCPF(inputValue) };
    } else {
      formattedInput = { type: 'CNPJ', value: formatCNPJ(inputValue) };
    }
  
    return formattedInput;
  };
  
  export default FormatDocument;
  