import React, { useState } from "react";
// Router
import { useLocation } from 'react-router-dom';
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer"

export default function Landing() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get('t');

  const [target, setTarget] = useState(value || 'site');

  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Pricing target={target} />
      <Footer />
    </>
  );
}


