import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import CloseIcon from "../../assets/svg/CloseIcon";
import { texts } from "../../texts";
import FormatMoney from "../../helpers/FormatMoney";

export default function PricingTable({ price, discount, paymentInterval, title, text, offers, action }) {

  const formatDiscount = (p, d) => {
    if (d && d > 0) {
      const formatedPrice = p - (p * (d / 100));
      return +formatedPrice.toFixed(2);
    } else {
      return p;
    }
  };

  const finalPrice = formatDiscount(price, discount);

  return (
    <Wrapper className="whiteBg radius8 shadow">
      {discount && discount > 0 && <>
        <div style={{
          margin: "15px 0",
          display: 'flex',
          justifyContent: 'center'
        }}>
          <p className="font15"
            style={{
              borderRadius: '5px',
              padding: '5px 10px',
              backgroundColor: '#13293d',
              color: '#fff'
            }}>{`Desconto de ${discount}%`}</p>
        </div>
      </>}

      <div style={{
        margin: "15px 0", display: 'flex',
        justifyContent: 'center'
      }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      {discount && discount > 0 && <>
        <div className="flexSpaceCenter" style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <p style={{ textDecoration: 'line-through' }}
            className="font10 ">{`${FormatMoney(price)} /mês`}</p>
        </div>
      </>}
      <div className="flexSpaceCenter" style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <p className="font25 extraBold" >{`${FormatMoney(finalPrice)} /mês`}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
            <div className="flexNullCenter" style={{ margin: "10px 0" }} key={index}>
              <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                {item.cheked ? (
                  <div style={{ minWidth: "20px" }}>
                    <svg style={{ color: '#00e009' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </div>
                ) : (
                  <div style={{ minWidth: "20px" }}>
                    <svg style={{ color: '#ff1414' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="font8 extraBold">{item.name}</p>
            </div>
          ))
          : null}
      </div>
      <div style={{ maxWidth: "150px", margin: "20px auto 0 auto" }}>
        <FullButton title={texts.pricing.buttons.trial} action={action} />
      </div>
    </Wrapper >
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
