import React from "react";
import { useLocation } from 'react-router-dom';

// Sections
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"


import TopNavbarSuccess from "../components/Nav/TopNavbarSuccess";

export default function Form() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('planId');
    const target = queryParams.get('t');
    console.info({ planId, target });

    return (
        <>
            <TopNavbarSuccess />
            <div style={{ margin: '40px 0px' }} />
            <Contact planId={planId} target={target} />
            <Footer />
        </>
    );
}


