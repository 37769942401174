

function FormatNumber(value) {

  // Remove todos os caracteres não numéricos
  const numericValue = value.replace(/\D/g, "");
  console.info(numericValue.length);

  if (numericValue.length > 16) return;

  return numericValue;

  // Verifique o comprimento do número de telefone e aplique a formatação apropriada
  if (numericValue.length <= 2) {
    return numericValue;
  } else if (numericValue.length <= 6) {
    return `(${numericValue.slice(0, 2)}`;
  } else if (numericValue.length <= 10) {
    return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 6)}`;
  } else if (numericValue.length <= 11) {
    return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;
  } else {
    // Se for um número de celular com DDD, use o formato (00) 9 0000-0000
    return `(${numericValue.slice(0, 2)}) 9 ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;
  }
}


export default FormatNumber;
