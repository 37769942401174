import React from "react";
// Sections
import TopNavbarSuccess from "../components/Nav/TopNavbarSuccess";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import SuccessPage from "../components/Sections/Success";

export default function Success() {
    return (
        <>
            <TopNavbarSuccess />
            <div style={{ margin: '40px 0px' }} />
            <SuccessPage />
            <Footer />
        </>
    );
}


