import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// Router
import { useNavigate } from 'react-router-dom';
// API
import api from "../../services/api"
// Assets
import equipe from "../../assets/img/equipe.png";
import { texts } from "../../texts";
// Helpers
import FormatDocument from "../../helpers/FormatDocument";
import FormatNumber from "../../helpers/FormatNumber";
import { toast } from "react-toastify";


const inicialState = {
  document: "",
  name: "",
  number: "",
  email: "",
  document: "",
  planId: null
};


const isFormValid = (formData) => {
  const requiredFields = ["name", "number", "email", "companyName", "document"];
  return requiredFields.every(field => formData[field].trim() !== "");
};

export default function Contact({ planId, target }) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(inicialState);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, planId: planId, target: target }));
  }, [planId, target]);

  const handleSubmit = async () => {

    if (!isFormValid(formData)) {
      toast.error("Por favor, preencha todos os campos do formulário.");
      return;
    }

    setLoading(true);
    try {

      const { data } = await api.post("/trial", formData);
      setTimeout(() => navigate(`/success`), 1000);

    } catch (err) {
      toast.error(err.response.data.error);
      console.error(err)

    } finally {

      setTimeout(() => setLoading(false), 2000);

    }
  }

  const formatDoc = (e) => {
    const id = e.target.id;
    const inputValue = e.target.value;

    if (id === "document") {
      const { type, value } = FormatDocument(inputValue);
      setFormData((prev) => ({ ...prev, documentType: type, [id]: value }));
    } else if (id === "number") {
      const number = FormatNumber(inputValue);
      setFormData((prev) => ({ ...prev, [id]: number }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: inputValue }));
    };

  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{texts.contact.title}</h1>
            <p className="font13">
              {texts.contact.text}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">Nome completo:</label>
                <input required onChange={e => formatDoc(e)} value={formData.name} autoComplete="off" type="text" id="name" name="name" className="font20 extraBold" />
                <label className="font13">Telefone:</label>
                <input required onChange={e => formatDoc(e)} value={formData.number} autoComplete="off" type="text" id="number" name="number" className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input required onChange={e => formatDoc(e)} value={formData.email} autoComplete="off" type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font13">Nome fantasia:</label>
                <input required onChange={e => formatDoc(e)} value={formData.companyName} autoComplete="off" type="text" id="companyName" name="companyName" className="font20 extraBold" />
                <label className="font13">CPF/CNPJ:</label>
                <input required onChange={e => formatDoc(e)} value={formData.document} autoComplete="off" type="text" id="document" name="document" className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                {loading ? (
                  <LoadingButton type="submit" value="Enviando" className="pointer animate radius8" disabled style={{ maxWidth: "220px" }} />
                ) : (
                  <ButtonInput type="submit" value="Iniciar Teste" onClick={handleSubmit} className="pointer animate radius8" style={{ maxWidth: "220px" }} />
                )}
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex hide-on-small-screen">
              <div style={{ width: "100%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={equipe} style={{ width: "100%", maxWidth: "470px", margin: '0px 10%' }} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;


const LoadingButton = styled.input`
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #a2a2a2;
    border: 1px solid #13293d;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;


const ButtonInput = styled.input`
  border: 1px solid #13293d;
  background-color: #13293d;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #13293d;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









