const messages = {
    pt: {
        topNavbar: {
            home: 'Inicio'
        },
        header: {},
        services: {},
        projects: {},
        pricing: {},
        contact: {},
        footer: {}
    }
};

export { messages };