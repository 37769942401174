import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Router
import { useNavigate } from 'react-router-dom';
// Components
import PricingTable from "../Elements/PricingTable";
import { texts } from "../../texts";
// API
import api from "../../services/api";
import Loading from "../Elements/Loading";

export default function Pricing({ target }) {

  const navigate = useNavigate();

  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([])

  const changePayment = () => setCheck(!check);

  function jsonToQueryString(json) {
    const params = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        params.append(key, json[key]);
      }
    }
    return params.toString();
  }

  function handleClick(planId) {
    const query = jsonToQueryString({ planId: planId })
    navigate(`/form?${query}&t=${target}`);
  }

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true)
      try {
        const { data } = await api.get("plans");
        setPlans(data)
      } catch (err) {
        console.error(err)
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    loadPlans()
  }, [])


  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{texts.pricing.title}</h1>
            <p className="font20">
              {texts.pricing.text}
            </p>
          </HeaderInfo>
          <HeaderActions>
            <ToggleContainer active={check}>
              <ToggleButton active={!check} onClick={changePayment}>
                Mensal
              </ToggleButton>
              <ToggleButton active={check} onClick={changePayment}>
                Anual
              </ToggleButton>
            </ToggleContainer>
          </HeaderActions>
          <TablesWrapper className="flexSpaceNull">
            {loading ? <Loading /> : plans?.map(plan => (<>
              <TableBox>
                <PricingTable
                  price={plan[check ? "yearValue" : "monthValue"]}
                  discount={plan[check ? "yearDiscount" : "monthDiscount"]}
                  paymentInterval={check ? "year" : "month"}
                  title={plan.name}
                  offers={JSON.parse(plan.details)}
                  action={() => handleClick(plan.uuid)}
                />
              </TableBox>
            </>))}
            {/*
            <TableBox>
              <PricingTable
                price={texts.pricing.prices[check ? "year" : "month"].basic.value}
                discount={texts.pricing.prices[check ? "year" : "month"].basic.discount}
                paymentInterval={check ? "year" : "month"}
                title={texts.pricing.plan.basic.title}
                offers={texts.pricing.plan.basic.details}
                action={() => handleClick(1)}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                price={texts.pricing.prices[check ? "year" : "month"].pro.value}
                discount={texts.pricing.prices[check ? "year" : "month"].pro.discount}
                paymentInterval={check ? "year" : "month"}
                title={texts.pricing.plan.pro.title}
                offers={texts.pricing.plan.pro.details}
                action={() => handleClick(2)}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                price={texts.pricing.prices[check ? "year" : "month"].plus.value}
                discount={texts.pricing.prices[check ? "year" : "month"].plus.discount}
                paymentInterval={check ? "year" : "month"}
                title={texts.pricing.plan.plus.title}
                offers={texts.pricing.plan.plus.details}
                action={() => handleClick(3)}
              />
            </TableBox>
           */}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  height: 45px;
  background-color: white;
  border: 1px solid #E3E8EC;
  border-radius: 36px;
  touch-action: none;
  transition: background 0.3s; /* Adicione uma transição suave */
`;

const ToggleButton = styled.button`
  flex: 1;
  border: none;
  border-radius: 36px;
  cursor: pointer;
  outline: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 15px;
  font-weight: 500;
  color: #7F93A8;
  transition: all 0.2s ease;
  background: ${(props) => (props.active ? "#13293d" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#7F93A8")};
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  },
  display: flex;
  justify-content: center;
`;

const HeaderActions = styled.div`
  display: flex;
  aling-items: center;
  justify-content: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




