import React from "react";
import styled, { keyframes } from "styled-components";

const loaderAnimation = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

const LoaderWrapper = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0B093B;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CubeFolding = styled.div`
  width: 50px;
  height: 50px;
  display: inline-block;
  transform: rotate(45deg);
  font-size: 0;
`;

const CubeLeaf = styled.span`
  position: relative;
  width: 25px;
  height: 25px;
  transform: scale(1.1);
  display: inline-block;

  &::before {
    content: "";
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 25px;
    height: 25px;
    transform-origin: 100% 100%;
    animation: ${loaderAnimation} 2.5s infinite linear both;
  }
`;

const Leaf2 = styled(CubeLeaf)`
  transform: rotateZ(90deg) scale(1.1);

  &::before {
    animation-delay: 0.3s;
    background-color: #f2f2f2;
  }
`;

const Leaf3 = styled(CubeLeaf)`
  transform: rotateZ(270deg) scale(1.1);

  &::before {
    animation-delay: 0.9s;
    background-color: #f2f2f2;
  }
`;

const Leaf4 = styled(CubeLeaf)`
  transform: rotateZ(180deg) scale(1.1);

  &::before {
    animation-delay: 0.6s;
    background-color: #e6e6e6;
  }
`;

export default function Loading() {
    return (
        <LoaderWrapper>
            <CubeFolding>
                <CubeLeaf className="leaf1" />
                <Leaf2 className="leaf2" />
                <Leaf3 className="leaf3" />
                <Leaf4 className="leaf4" />
            </CubeFolding>
        </LoaderWrapper>
    );
}
