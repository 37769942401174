import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import { ReactComponent as UniChatLight } from "../../assets/svg/UniChatLight.svg";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <UniChatLight style={{ width: '100%', width: '40px', height: '40px' }} />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Unichat
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              <CompanyData>
                <div>
                  Unitech Sistemas
                </div>
                <div>
                  CNPJ 13.234.946/0001-64.
                </div>
                <div>
                  Av Tocantins QD 18, LT 31, Sala 02, Taquaralto,
                </div>
                <div>
                  Palmas - TO, 77.064-580
                </div>
              </CompanyData>
            </StyleP>
            <StyleP className="whiteColor font13">
              <div>
                Fale conosco <a href="https://wa.me/5563991000057" target="_blank" style={{ color: '#628ef4', fontWeight: 'bold', cursor: 'pointer' }}>(63) 9 9100-0057</a>
              </div>
            </StyleP>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span style={{ color: '#628ef4' }} className="font13">Unichat</span> Todos os direitos reservados.
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Voltar para o topo
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const CompanyData = styled.div`
  display: flex;
  flex-direction: column;
`