import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from "./screens/Landing.jsx";
import Form from "./screens/Form.jsx";
import Success from "./screens/Success.jsx";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/form" element={<Form />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
