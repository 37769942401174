const texts = {
    topNavbar: {
        home: 'Início',
        services: 'Serviços',
        projects: 'Nosso Sistema',
        pricing: 'Preços',
        contact: 'Contato',
        login: 'Acesse a Plataforma',
        trial: 'Iniciar Teste Grátis'
    },
    header: {
        title: "Transforme seu Atendimento",
        text: "Nossa base técnica sólida garante um desempenho robusto e seguro, permitindo que sua marca se destaque no mercado.",
        button: { trial: "Eu Quero!" },
        client: { name: "Anderson Targino", text: "Uma das melhores ferramentas que encontrei para interagir com os clientes." }
    },
    services: {
        title: "Serviços Sólidos e Constantes",
        text: "Mantenha consistência e agilidade no contato com seus clientes e elimine tempos de espera.",
        subHeader: {
            title: "Ganhos Constantes",
            text1: "Aumente sua presença de maneira estável, segura e rentável!",
            text2: "Ao confiar os aspectos técnicos aos especialistas, você pode aprimorar sua estratégia comercial, reduzir despesas operacionais e se posicionar de forma sólida para aproveitar as oportunidades de mercado, contribuindo para um empreendimento mais eficiente e lucrativo."
        },
        items: {
            efficient: {
                title: "Otimize a Eficiência do Atendimento",
                text: "Simplifique o atendimento e economize tempo."
            },
            personalized: {
                title: "Atendimento Personalizado e Automatizado",
                text: "Automatize tarefas e crie experiências únicas."
            },
            management: {
                title: "Gerencie Conversas com Facilidade",
                text: "Elimine a confusão e aprimore a experiência do cliente."
            },
            integration: {
                title: "Integração Simples com Suas Ferramentas",
                text: "Integre nossa solução facilmente às suas ferramentas de atendimento."
            }
        },
        buttons: {
            itrial: "Eu Quero!",
            trial: "Teste Grátis",
            more: "Saiba Mais"
        }
    },
    projects: {
        title: "Sistema Intuitivo e Completo para o seu Negócio",
        text: `Obtenha uma solução abrangente e descomplicada para o seu negócio e diga adeus às preocupações com os clientes.`,
        pages: {
            dashboard: { title: "Dashboard", text: "Tenha acesso diário a informações relevantes sobre o seu atendimento." },
            ticket: { title: "Atendimento", text: "Uma tela de atendimento completa com todas as informações necessárias para oferecer um excelente serviço." },
            status_user: { title: "Controle de Equipe", text: "Mantenha o controle total da sua equipe para uma gestão eficiente." },
        }
    },
    pricing: {
        title: "Opções de Preços Personalizadas",
        text: "Planos sob medida para atender às necessidades do seu negócio.",

        plan: {
            basic: {
                title: "Básico",
                details: [
                    { name: "Até 5 Atendentes", cheked: true },
                    { name: "2 Conexões", cheked: true },
                    { name: "WhatsApp", cheked: true },
                    { name: "Conversas Ilimitadas", cheked: true },
                    { name: "Contatos Ilimitados", cheked: true },
                    { name: "API e WebHook", cheked: false },
                    { name: "10GB de Armazenamento", cheked: true },
                    { name: "Chatbot Integrado", cheked: false },
                    { name: "Backup de Conversas por 1 Ano", cheked: true },
                    { name: "Suporte 24/7", cheked: true },
                ]
            },
            pro: {
                title: "Pro",
                details: [
                    { name: "Até 10 Atendentes", cheked: true },
                    { name: "4 Conexões", cheked: true },
                    { name: "WhatsApp e Telegram", cheked: true },
                    { name: "Conversas Ilimitadas", cheked: true },
                    { name: "Contatos Ilimitados", cheked: true },
                    { name: "API e WebHook", cheked: false },
                    { name: "10GB de Armazenamento", cheked: true },
                    { name: "Chatbot Integrado", cheked: true },
                    { name: "Backup de Conversas por 1 Ano", cheked: true },
                    { name: "Suporte 24/7", cheked: true },
                ]
            },
            plus: {
                title: "Plus",
                details: [
                    { name: "Até 20 Atendentes", cheked: true },
                    { name: "6 Conexões", cheked: true },
                    { name: "WhatsApp e Telegram", cheked: true },
                    { name: "Conversas Ilimitadas", cheked: true },
                    { name: "Contatos Ilimitados", cheked: true },
                    { name: "API e WebHook", cheked: true },
                    { name: "10GB de Armazenamento", cheked: true },
                    { name: "Chatbot Integrado", cheked: true },
                    { name: "Backup de Conversas por 1 Ano", cheked: true },
                    { name: "Suporte 24/7", cheked: true },
                ]
            },
        },
        prices: {
            month: {
                basic: { value: 200.00, discount: 15 },
                pro: { value: 499.00, discount: 15 },
                plus: { value: 899.00, discount: 15 },
            },
            year: {
                basic: { value: 200.00, discount: 25 },
                pro: { value: 499.00, discount: 25 },
                plus: { value: 899.00, discount: 25 },
            },
        },
        buttons: {
            trial: "Contratar",
        }
    },
    contact: {
        title: "Crie uma Conta e Comece Agora!",
        text: "Uma nova experiência o aguarda!"
    },
    success: {
        title: "Sua conta foi criada com sucesso!",
        text: "As informações da sua conta foram enviadas para o seu e-mail.",
        button: "Começar a usar!"
    },
    footer: {}
};

export { texts };